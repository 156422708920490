import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';
import { getPlanFromPlanID } from '@pkgs/shared/plans';
import useAuthSubscriptions from './useAuthSubscriptions';

const useIsTeamEnterprise = (config): boolean => {
	const subscriptions = useAuthSubscriptions();

	const subscription = subscriptions.find((sub) => sub.type === SubscriptionType.TEAM);

	const currentPlan = getPlanFromPlanID(config, subscription?.planID);

	return currentPlan?.tier === SubscriptionTier.ENTERPRISE;
};

export default useIsTeamEnterprise;
